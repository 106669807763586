
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import * as StatServices from '@/services/StatServices'

@Component
export default class TotalInteractionsChart extends Vue {
  @Prop({ required: true }) from!: Date;
  @Prop({ required: true }) to!: Date;

  loading = true;

  interactions: Record<string, any[]> = {}

  chartOptions = {
    chart: {
      id: 'vistors'
    },
    stroke: {
      curve: 'smooth'
    },
    xaxis: {
      type: 'datetime',
    },
    dataLabels: {
      enabled: false
    },
  }

  series = [] as any[]


  @Watch('from', { immediate: true })
  @Watch('to')
  async fetchInteractions() {
    this.loading = true;
    this.interactions = await StatServices.ListInteractions(
      this.from,
      this.to
    );

    let clicksData = [] as any[];
    let clickTroughData = [] as any[];

    for (let date in this.interactions) {
      let interactions = this.interactions[date];
      clicksData.push([new Date(date).getTime(), interactions.filter((e: any) => e.type === 0).length])
      clickTroughData.push([new Date(date).getTime(), interactions.filter((e: any) => e.type === 1).length])
    }


    clicksData.sort((a, b) => a[0] - b[0])
    clickTroughData.sort((a, b) => a[0] - b[0])

    this.series = [
      {
        name: 'Klik factcheck items',
        data: clicksData
      },
      {
        name: 'Doorverwijzing naar artikel van partner',
        data: clickTroughData
      }
    ]

    console.log('series')
    console.log(this.series)
    this.$forceUpdate();

    this.loading = false;
  }
}
